import { gql, useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makePagination } from '@/utils/pagination';
import {
  PlayerInfoHistory,
  PlayerInfoHistoryVariables,
} from './__generated__/PlayerInfoHistory';
import { queryParams } from './queryParams';

const QUERY = gql`
  query PlayerInfoHistory(
    $after: String
    $before: String
    $brand: BrandEnum
    $first: Int
    $last: Int
    $playerId: ID!
  ) {
    viewer {
      id
      playerInfoHistory(
        after: $after
        before: $before
        brand: $brand
        first: $first
        last: $last
        playerId: $playerId
      ) {
        edges {
          node {
            processedAt
            eventType
            player {
              firstName
              lastName
              middleName
              birthDate
              brand {
                id
                name
              }
              addressInfo {
                fullAddress
              }
              emailInfo {
                email
              }
              phoneNumber
            }
            initiator {
              __typename
              ... on PlayerInitiator {
                player {
                  id
                  firstName
                  lastName
                  email
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerInfoHistory(playerId: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    PlayerInfoHistory,
    PlayerInfoHistoryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makePagination(query, setQuery, data?.viewer.playerInfoHistory.pageInfo),
    history: data?.viewer.playerInfoHistory?.edges?.map((edge) => edge?.node),
  };
}
